<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" :rules="dataRule">
      <div v-for="(col,index) in dataForm.cols">
        <el-form-item :label="col.name" v-if="col.cols==='id'">
          <el-input disabled style="width: 80%;" v-model="col.value"></el-input>
        </el-form-item>
        <el-form-item :label="col.name" v-else-if="col.cols==='create_time'">
          <el-date-picker disabled style="width: 80%;" v-model="col.value"></el-date-picker>
        </el-form-item>
        <el-form-item :label="col.name" v-else-if="col.cols==='create_user'">
          <el-input disabled style="width: 80%;" v-model="col.value"></el-input>
        </el-form-item>
        <el-form-item :label="col.name" v-else-if="col.cols==='hbaseTableName'">
          <el-input disabled style="width: 80%;" v-model="col.value"></el-input>
        </el-form-item>
        <el-form-item :label="col.name" :prop="'cols.'+index+'.value'" :rules="{type:'integer',required: true, message: '请输入数字', trigger: 'blur'}" v-else-if="col.type==='bigint'">
          <el-input  style="width: 80%;" v-model.number="col.value"  type="integer"></el-input>
        </el-form-item>
        <el-form-item :label="col.name" :prop="'cols.'+index+'.value'" :rules="{required: true, message: '参数不能为空', trigger: 'blur'}" v-else-if="col.type==='varchar(255)'" >
          <el-input style="width: 80%;"  v-model="col.value"></el-input>
        </el-form-item>
        <el-form-item :label="col.name" :prop="'cols.'+index+'.value'" :rules="{required: true, message: '参数不能为空', trigger: 'change'}" v-else-if="col.type==='date'" >
          <el-date-picker type="date" v-model="col.value"></el-date-picker>
        </el-form-item>
        <el-form-item :label="col.name" :prop="'cols.'+index+'.value'" :rules="{required: true, message: '参数不能为空', trigger: 'change'}" v-else-if="col.type==='bool'" >
          <el-radio-group v-model="col.value">
            <el-radio :label=1 >是</el-radio>
            <el-radio :label=0 >否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {treeDataTranslate} from "@/utils";

export default {
  name: "project-add-or-update",
  data () {
    return {
      isLeaf: 0,
      rules:{
        number:[
          { type:'integer', required: true, message: '请输入数字', trigger: 'blur'},
        ],
        string:[
          {required: true, message: '输入不能为空', trigger: 'blur'},
        ],
        date:[
          { required: true, message: '输入不能为空', trigger: 'change'},
        ]
      },
      visible: false,
      dataForm: {
        id: 0,
        cols: [],
        name: '',
        hasHbasetable: '',
        parentName: "",
      },
      parentId:0,
      dataRule: {
        parentName: [
          { required: true, message: '父层级不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '层级名称不能为空', trigger: 'blur' }
        ],
        hasHbasetable: [
          { required: true, message: '是否有对应hase表不能为空', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: 'qwe', trigger: 'blur' }
        ],
      },
      dataList: [],
      menuListTreeProps: {
        label: 'name',
        children: 'children'
      },
      selectOption:[
        {
          id:0,name:'ceshi'
        }
      ],
      enterState: 0,   //进入状态，0代表直接进入，1代表通过层级进入，2代表搜索进入（显示全层级的），4代表搜索进入（显示特定父层级下）
    }
  },
  created() {
    this.tableId = window.sessionStorage.getItem("tableId")
  },
  mounted() {
    this.tableId = window.sessionStorage.getItem("tableId")
  },
  methods: {
    init (id) {
      this.enterState = window.sessionStorage.getItem("enterState")
      this.dataForm.id = id || 0
      this.visible = true
      this.dataListLoading = true
      this.dataForm.cols = []

        if (this.dataForm.id === 0) {
          this.$http({
            url: this.$http.adornUrl('/levelManage/projectattributes/getCols'),
            method: 'get',
          }).then(({data}) => {
            if (data && data.code === 0) {
              for (let i = 0; i < data.data.length; i++) {
                  this.dataForm.cols.push({
                    name: data.data[i].remark,
                    cols: data.data[i].name,
                    type: data.data[i].type,
                })
              }
            }
          })
        }
        else {
          this.$http({
            url: this.$http.adornUrl('/levelManage/projectattributes/getInfo?rowId=' + id),
            method: 'get',
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.cols = []
              for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].type === 'bigint') {
                  this.dataForm.cols.push({
                    name: data.data[i].remark,
                    cols: data.data[i].name,
                    type: data.data[i].type,
                    value: data.data[i].value * 1,
                  })
                } else if (data.data[i].type === 'bool') {
                  if (data.data[i].value === "true") {
                    this.dataForm.cols.push({
                      name: data.data[i].remark,
                      cols: data.data[i].name,
                      type: data.data[i].type,
                      value: 1,
                    })
                  } else {
                    this.dataForm.cols.push({
                      name: data.data[i].remark,
                      cols: data.data[i].name,
                      type: data.data[i].type,
                      value: 0,
                    })
                  }
                } else {
                  this.dataForm.cols.push({
                    name: data.data[i].remark,
                    cols: data.data[i].name,
                    type: data.data[i].type,
                    value: data.data[i].value,
                  })
                }
              }
            }
          })
        }
    },

    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/levelManage/projectattributes/${!this.dataForm.id ? 'save' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'cols': this.dataForm.cols,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                    this.visible = false
                    this.$parent.init()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
        else {
          this.$message.error("表单格式错误")
        }
      })
    }


  }
}
</script>

<style scoped>

</style>